// src/utils/request.ts

import router from "@/router";
import { Message } from "@/utils/tips";
import axios, {
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
} from "axios";

export interface HttpResponse<T = unknown> {
  code: number;
  message: string;
  result: T;
}

// 防止重复触发 mgLogout 请求
let isExecutingLogout = false;

// const baseURL = "https://beta3.mugua.ai.bkgc.tech";
const baseURL = "/";

// 创建一个 axios 实例
const axiosInstance: any = axios.create({
  baseURL: baseURL, // 设置基础URL
  timeout: 300000, // 请求超时时间
  // 其他 axios 配置项
});

// 请求拦截器
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    // 对请求错误做些什么
    console.error("请求错误:", error);
    return Promise.reject(error);
  }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
  async (response: AxiosResponse) => {
    // 对响应数据做些什么
    if (response.data.code === 202) {
      Message.error("账号在其他地方登录或登录时间过长, 请重新登录");
      localStorage.clear();
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
      });
      router.replace("/login");
    }
    return response.data;
  },
  (error: AxiosError) => {
    // 对响应错误做些什么
    if (error.response) {
      // 请求已发出，但服务器响应状态码不是 2xx
      switch (error.response.status) {
        case 401:
          console.error("未授权，请重新登录");
          // 这里可以做登出操作，例如清除 token 并重定向到登录页面
          localStorage.removeItem("token");
          window.location.href = "/login";
          break;
        case 403:
          console.error("禁止访问");
          break;
        case 404:
          console.error("请求的资源不存在");
          break;
        case 500:
          console.error("服务器错误");
          if (!isExecutingLogout) {
            isExecutingLogout = true;
            Message.error("服务器出错啦, 请稍后重试~");
            localStorage.clear();
            document.cookie.split(";").forEach((c) => {
              document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
            });
            router.push({ name: "login" });
          }
          break;
        default:
          console.error(`未知错误: ${error.response.status}`);
      }
    } else if (error.request) {
      // 请求已发出但没有响应
      console.error("请求无响应:", error.request);
    } else {
      // 其他错误
      console.error("错误信息:", error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
