import axios from "axios";

import ApiUrl from "./url";

export function getRightsListApi(isDelete: boolean) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.PayGetRightsList,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      isDelete,
    },
  };
  return axios.request(config);
}

export function checkPaymentStatusApi(orderId: string) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.PayCheckOrderls,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      orderId,
    },
  };
  return axios.request(config);
}
export function getNewOrderApi(userId: string) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.PaygetNewOrder,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userId,
    },
  };
  return axios.request(config);
}

export function getUserCommodityApi(userId: string, isValid: boolean) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.PayGetUserCommodity,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userId,
      isValid,
    },
  };
  return axios.request(config);
}

export function getUserContractCountApi(userId: string) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.PayGetUserContractCount,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userId,
    },
  };
  return axios.request(config);
}

export function getUserCouponsApi(userId: string, isUsed?: boolean) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.PayGetUserCoupons,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userId,
      isUsed,
    },
  };
  return axios.request(config);
}

export function getIsUsingRightsApi(userId: string) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.PayGetIsUsingRights,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userId,
    },
  };
  return axios.request(config);
}
export function getAvailableCouponListApi(userId: string, rightsId: string) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.PayGetAvailableCouponList,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userId,
      rightsId,
    },
  };
  return axios.request(config);
}

export function getCalculateFinalPriceApi(data: any) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.PayGetCalculateFinalPrice,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios.request(config);
}

export function getOrderByUserIdApi(data: any) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.PayGetOrderByUserId,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios.request(config);
}

export function getPayGetSecondaryCardApi() {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.PayGetSecondaryCard,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.request(config);
}

export function getUserCouponDetaildApi(data: any) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.PayGetUserCouponDetaild,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios.request(config);
}

export function getExpireTimeApi(data: any) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.PayGetExpireTime,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios.request(config);
}

export function getTemplateSecondaryCardApi() {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.PayGetTemplateSecondaryCard,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.request(config);
}
