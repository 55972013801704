enum ApiUrl {
  generate = "/api2/draftDocument/generate",
  Register = "/auth/register",
  RegisterGetVerificationCode = "/api2/users/getVerificationCode",
  Login = "/auth/login",
  Logout = "/api2/users/logout",
  // UserMe = "/user/me",

  Conversation = "/conv",
  AllConversation = "/conv/all",
  UserList = "/user",

  ChatPlugin = "/chat/openai-plugin",
  ChatPlugins = "/chat/openai-plugins",
  InstalledChatPlugins = "/chat/openai-plugins/installed",

  ServerStatus = "/status/common",

  SystemInfo = "/system/info",
  SystemRequestStatistics = "/system/stats/request",
  SystemAskStatistics = "/system/stats/ask",
  SystemActionSyncOpenaiWebConversations = "/system/action/sync-openai-web-conv",
  SystemCheckOpenaiWebAccount = "/system/check-openai-web-account",
  SystemUseActivationCode = "/api2/experience/useExperienceCard",

  ServerLogs = "/logs/server",
  CompletionLogs = "/logs/completions",

  SystemConfig = "/system/config",
  SystemCredentials = "/system/credentials",
  SystemGetRoleById = "/api2/role/getRoleById",
  SystemUpdateFunctionCount = "/api2/role/updateFunctionCount",
  SystemMarginStatistics = "/api2/userSurplus/getSurplusList",
  SystemStatisticsContractDetail = "/api2/userSurplus/getContractDetail",
  SystemStatisticsFileDetail = "/api2/userSurplus/getFileDetail",
  SystemGetUserRecord = "/api2/record/getRecord",
  SystemGetRecordById = "/api2/record/getRecordById",

  FilesLocalUpload = "/files/local/upload",
  FilesLocalDownload = "/files/local/download",
  FilesOpenaiWebUploadStart = "/files/openai-web/upload-start",
  FilesOpenaiWebUploadComplete = "/files/openai-web/upload-complete",
  FilesLocalUploadToOpenaiWeb = "/files/local/upload-to-openai-web",

  LongOutline = "/api2/article/getOutLine",
  LongOutlineTest = "/api2/test/jwt",
  LongUploadFiles = "/api2/intelligent/intelligentProSaveFile",
  LongUploadLink = "/api2/article/uploadUrl",
  LongDocViewFile = "/api2/article/preview",
  LongHistoryDoc = "/api2/fileHistory/getFileListByUser",
  LongSaveDoc = "/api2/article/save",
  LongFileContent = "/api2/fileHistory/getFileContentById",
  LongGetTaskId = "/api2/article/initArticleTask",
  LongUpdateArticle = "/api2/article/updateArticle",
  LongGoEdit = "/api2/article/goEdit",
  LongPPTView = "/api2/article/goPPT",
  LongDelFile = "/api2/article/deleteFile",
  LongDelHistoryFile = "/api2/fileHistory/deleteHistory",
  LongGetSourceFromKeyWords = "/api2/search/getSourceFromKeyWords",

  ProUploadFiles = "/api2/intelligent/intelligentProSaveFile",
  ProWebsoketChat = "/api2/intelligent/pro",
  ProAllHistoryList = "/api2/dialogPro/allHistory",
  ProGetHistoryById = "/api2/dialogPro/getHistoryById",
  ProDeleteConversation = "/api2/dialogPro/deleteConversation",
  ProAddNewConversation = "/api2/dialogPro/addConversation",
  ProReNameConversation = "/api2/dialogPro/updateConversation",
  ProUploadUrl = "/api2/intelligent/saveUrl",
  ProGetCloudConversationFilesById = "/api2/dialogPro/getConversationFile",
  ProDelCloudConversationFilesById = "/api2/dialogPro/deleteConversationFile",

  ProTextModel = "/openai/universal/pro/v1/chat/conversation",

  LoginRegister = "/api2/users/register",
  LoginUrl = "/api2/users/login",
  LoginSmsUrl = "/api2/users/smsLogin",
  LoginUserInfo = "/api2/users/me",

  FunctionalCasesTableData = "/api2/search/getSimilarCase",
  FunctionalCasesData = "/api2/intelligent/similarCase",

  DialogGetWebsocket = "/api2/intelligent/consult",
  DialogDeleteConversation = "/api2/dialogPro/deleteLawConversation",

  UserGetList = "/api2/users/getUserList",
  UserDeleteApi = "/api2/users/deleteUser",
  UserUpdateRoleApi = "/api2/users/updateUser",
  UserGetRoleApi = "/api2/permission/getPermissionList",

  ContractGetContent = "/api2/contractReview/getContractContent",
  ContractGetType = "/api2/contractType/get",
  ContractGetConversationId = "/api2/contractReview/getConversationId",
  ContractGetNewContractResult = "/api2/contractReview/getContractResult",
  ContractUploadFile = "/api2/intelligent/intelligentProSaveFile",
  ContractUpdateAnnotation = "/api2/contractHistory/updateViewAnnotation",
  ContractGetAllHistory = "/api2/contractHistory/getContractList",
  ContractDelHistoryById = "/api2/contractHistory/deleteContractHistory",
  ContractRenameHistoryById = "/api2/contractHistory/updateContractHistory",
  ContractUpdate = "/api2/contractHistory/updateContractHistory",
  ContractdeleteAnnotation = "/api2/contractHistory/deleteAnnotation",
  ContractGetAllContentById = "/api2/contractReview/getContractById",
  ContractExportFileById = "/api2/contractReview/export",
  ContractDelFileById = "/api2/dialogPro/deleteConversationFile",
  ContractWebscoketUrl = "/api2/ws/contract",
  ContractNewReviewDetail = "/api2/contractHistory/finalContractReview",

  SearchSimilarCase = "/api2/search/getSimilarCase",
  SearchSourceFromNet = "/api2/search/getSourceFromNet",

  CheckToken = "/api2/tokenVerify/checkToken",

  ArkoseInfo = "/arkose/info",

  AnalysisGetAnalysisId = "/api2/caseAnalysis/createCaseAnalysisId",
  AnalysisIntelligentAnalysis = "/api2/caseAnalysis/intelligentAnalysis",
  AnalysisGetHistory = "/api2/caseAnalysis/getAnalysisHistory",
  AnalysisDelHistory = "/api2/caseAnalysis/deleteAnalysis",
  AnalysisGetHistoryDetail = "/api2/caseAnalysis/getAnalysisHistoryById",
  AnalysisExport = "/api2/caseAnalysis/export",
  AnalysisUpdate = "/api2/caseAnalysis/updateCaseAnalysis",
  AnalysisWebscoket = "/api2/ws/caseAnalysis",

  InvitationCode = "/api2/users/share",

  UserInfo = "/api2/users/getUserById",
  UserUpdatePassword = "/api2/users/updatePassword",
  UserBindPhone = "/api2/users/bindPhone",
  UserUpdateInfo = "/api2/users/updateUser",
  UserCreateWeChatQrCode = "/api2/wx/createWeChatQrCode",

  DialogueGetAllHistory = "/api2/dialogPro/allLawHistory",
  DialogueGetHistoryById = "/api2/dialogPro/getLawHistoryById",

  PayGetRightsList = "/api2/rights/getRightsList",
  PayCheckOrderls = "/api2/order/getOrderIsPayed",
  PaygetNewOrder = "/api2/order/getNewOrder",
  PayGetUserCommodity = "/api2/userRights/getUserRights",
  PayGetUserContractCount = "/api2/contractReview/getContractCount",
  PayGetUserCoupons = "/api2/userCoupon/getUserCoupons",
  PayGetIsUsingRights = "/api2/userRights/getIsUsingRights",
  PayGetAvailableCouponList = "/api2/coupon/getAvailableCouponList",
  PayGetCalculateFinalPrice = "/api2/order/calculateFinalPrice",
  PayGetOrderByUserId = "/api2/order/getOrderByUserId",
  PayGetSecondaryCard = "/api2/rights/getSecondaryCard",
  PayGetUserCouponDetaild = "/api2/userCoupon/getUserCouponDetail",
  PayGetExpireTime = "/api2/order/calculateExpireTime",
  PayGetTemplateSecondaryCard = "/api2/rights/getTemplateSecondaryCard",

  TemplateGetContractTemplateList = "/api2/contractTemplateType/getContractTemplateList",
  TemplateGetTemplateById = "/api2/contractTemplate/getTemplateById",
  TemplateGetTemplateContentById = "/api2/contractTemplate/downloadTemplateById",
}

export default ApiUrl;
